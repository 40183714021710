<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refLeadListTable"
      :items="allSalesLeads.data"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: ID -->
      <template #cell(ID)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.ID }}
          </span>
        </b-media>
      </template>
      <!-- Column:Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.firstname }}  {{ data.item.lastname }}
          </span>
        </b-media>
      </template>
      <!-- Column:Email -->
      <template #cell(Email)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.email }}
          </span>
        </b-media>
      </template>
      <!-- Column:Phonenumber -->
      <template #cell(Phonenumber)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.country_code }}{{ data.item.phonenumber.replace(/^0+/, '') }}
          </span>
        </b-media>
      </template>
      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveSalesLeadStatus(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
      <!-- Column:CreateAt -->
      <template #cell(CreateAt)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.created_timestamp) }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.ID}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer ml-1"
            size="16"
            @click="$router.push({ name: 'sales-lead-configuration-id', params: { id: data.item.ID }})"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalSalesLeads"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow, BCol, BFormInput, BTable,
  BMedia, BBadge,
  BPagination, VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { ref, computed } from '@vue/composition-api'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  setup() {
    const tableColumns = [
      { key: 'ID', sortable: false },
      { key: 'Name', sortable: false },
      { key: 'Email', sortable: false },
      { key: 'Phonenumber', sortable: false },
      { key: 'Status', sortable: false },
      { key: 'CreateAt', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refLeadListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalSalesLeads = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref('')

    const dataMeta = computed(() => {
      const localItemsCount = refLeadListTable.value ? refLeadListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSalesLeads.value,
      }
    })

    const refetchData = () => {
      refLeadListTable.value.refresh()
    }

    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalSalesLeads,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLeadListTable,
      statusFilter,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  created() {
    this.fetchSalesLeads()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('report', ['allSalesLeads']),
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    currentPage: {
      handler() {
        this.fetchSalesLeads()
      },
    },
  },
  methods: {
    ...mapActions('report', ['fetchAllSalesLeads']),
    fetchSalesLeads() {
      const payload = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      try {
        this.fetchAllSalesLeads(payload).then(() => {
          this.totalSalesLeads = this.allSalesLeads.paginator.count
        })
      } catch (error) {
        this.errorMessage('There was an issue with fetching the lead list')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
